import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

const baseOptions = { staleTime: 30000 };
// const baseOptions = { staleTime: 30000, cacheTime: 1000000 };

export const useQueryWrapper = <TData>({
  queryKey,
  options,
  queryFn,
}: {
  queryKey: QueryKey;
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn' | 'initialData'>;
  queryFn: () => Promise<TData>;
}): UseQueryResult<TData> => {
  const optionParams = options ? { ...baseOptions, ...options } : baseOptions;

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = queryFn();
      return response;
    },
    ...optionParams,
  }) as UseQueryResult<TData>;
};
