import React, { useEffect } from 'react';

import { useNavigate } from 'react-router';

export const ErrorFallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/error');
  }, [navigate]);

  return null;
};
