import styled from '@emotion/styled';

export const LayoutPcContents = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  flex-shrink: 0;
  background: #eceeef;
`;

export const MainContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

export const PageContents = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;
