import jwtDecode from 'jwt-decode';
import { DefaultValue, selector } from 'recoil';

import { AuthPostResponseType } from '@/api/auth';
import { AuthType } from '@/shared/types/common/type';
import { authInformationState, tokenState } from '@/states/atoms/authInformationState';

export const authInformationSelector = selector<Partial<AuthType>>({
  key: 'authInformationSelector',
  get: ({ get }) => get(authInformationState),
});

export const tokenSelector = selector<AuthPostResponseType>({
  key: 'tokenSelector',
  get: ({ get }) => get(tokenState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // NOTE: DefaultValue型のときはresetから呼ばれたとき
      reset(tokenState);
      reset(authInformationState);
      return;
    }

    const decodedAccessToken: AuthType = jwtDecode(newValue.access_token);
    set(tokenState, newValue);
    set(authInformationState, decodedAccessToken);
  },
});
