import { z } from 'zod';

import { SORT_ORDER } from '@/shared/constants/code';

const SortUnion = z.union([z.literal(SORT_ORDER.ASC), z.literal(SORT_ORDER.DESC)]);
// 以下でも同じ
// export const SortOrderEnum = z.enum([SORT_ORDER.ASC, SORT_ORDER.DESC]);

// "asc" | "desc"
export type SortOrder = z.infer<typeof SortUnion>;
export const SortOption = z.object({ sortTarget: z.string(), sortOrder: SortUnion });

export const AnySchema = z.record(z.any());
export type AnyType = z.infer<typeof AnySchema>;

export const DeleteMultipleObject = z.object({ ids: z.array(z.number().min(1)) });

// 共通リクエスト
export const GetListRequest = z.object({
  offset: z.number().nullish(),
  limit: z.number().nullish(),
  sortTarget: z.string().nullish(),
  sortOrder: SortUnion.nullish(),
});
export const VoidRequest = z.void();
export type VoidRequestType = z.infer<typeof VoidRequest>;
export type GetListRequestType = z.infer<typeof GetListRequest>;
export type DeleteMultipleRequestType = z.infer<typeof DeleteMultipleObject>;

// 共通レスポンス

// 型チェックしない場合使用可
export const SaveResponse = z.union([
  z.object({ data: AnySchema }),
  z.object({ rows: z.array(AnySchema).nullish() }),
]);

export type RegisterResponseType = z.infer<typeof SaveResponse>;
export type UpdateResponseType = z.infer<typeof SaveResponse>;

export const DeleteResponse = z.object({ id: z.number().min(1) });
export type DeleteResponseType = z.infer<typeof DeleteResponse>;

export type DeleteMultipleResponseType = z.infer<typeof DeleteMultipleObject>;
