import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

export const useOptimisticMutation = <TVariables, TData, TContext>({
  queryKey,
  fetcher,
  updater,
  options,
}: {
  queryKey: QueryKey;
  // TODO voidを入れると使う側で常にvoidのケースを考慮したなくてはいけないので要調整
  fetcher: (params: TVariables) => Promise<TData | void>;
  updater?: ((oldData: TContext, newData: TVariables) => TContext) | undefined;
  options?: Omit<
    UseMutationOptions<TData | void, unknown, TVariables, TContext>,
    'onMutate' | 'onError' | 'onSettled'
  >;
}) => {
  // const { accessToken } = useAuthGuardContext();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => fetcher(params),
    // return fetcher(params, accessToken || '');
    onMutate: async (data) => {
      // console.log(data);
      await queryClient.cancelQueries(queryKey);

      const previousData = queryClient.getQueryData<TContext>(queryKey);

      // console.log(previousData);
      if (previousData && updater) {
        queryClient.setQueryData<TContext>(queryKey, updater(previousData, data));
      }

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(queryKey, context);
      console.warn(err);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    ...options,
  });
};
