import React from 'react';

import {
  SideBar as SideBarComponents,
  SideBarNaviFooterItem,
  SideBarNaviItem,
} from '@open-associates/roborobo-ui-components';

import { useSideBar } from '@/components/parts/SideBar/hooks';
import { routes } from '@/shared/routes';
import { findSubItemRoutes } from '@/shared/routes/findSubItemRoutes';

export const SideBar: React.FC = () => {
  const {
    open,
    menuRoutes,
    footerMenuRoutes,
    isActive,
    handleChangeOpen,
    clickMenu,
    clickExternalLink,
  } = useSideBar();
  return (
    <SideBarComponents
      open={open}
      handleChangeOpen={handleChangeOpen}
      naviItems={
        <>
          {menuRoutes.map((route) => (
            <SideBarNaviItem
              key={route.path}
              open={open}
              Icon={route.Icon}
              to={() => clickMenu(route)}
              label={route.menuTitle ?? route.title}
              active={isActive(route.category)}
              subItems={findSubItemRoutes(route, routes)?.map((item) => ({
                to: () => clickMenu(item),
                label: item.title,
                active: isActive(item.category, item.path),
              }))}
            />
          ))}
        </>
      }
      naviFooterItems={
        <>
          {footerMenuRoutes.map((route) => (
            <SideBarNaviFooterItem
              key={route.path}
              open={open}
              Icon={route.Icon}
              to={() => clickExternalLink(route.path)}
              label={route.menuTitle ?? route.title}
            />
          ))}
        </>
      }
    />
  );
};
