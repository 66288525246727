import { atom } from 'recoil';

import { AuthPostResponseType } from '@/api/auth';
import { localstorage } from '@/shared/constants/localstorage';
import { AuthType } from '@/shared/types/common/type';
import {
  localStorageAuthInformationEffect,
  localStorageEffect,
} from '@/states/effect/localStorageEffect';

export const tokenState = atom<AuthPostResponseType>({
  key: 'tokenState',
  default: {
    access_token: '',
    refresh_token: '',
    token_type: '',
    expires_in: 0,
    scope: '',
  },
  // effects_UNSTABLE: [],
  effects: [
    localStorageEffect([
      localstorage().authInformation.accessToken,
      localstorage().authInformation.refreshToken,
    ]),
  ],
});

export const authInformationState = atom<Partial<AuthType>>({
  key: 'authInformationState',
  default: {},
  effects: [localStorageAuthInformationEffect(localstorage().authInformation.accessToken)],
});
