import { z } from 'zod';

import {
  DeleteMultipleObject,
  DeleteMultipleRequestType,
  DeleteMultipleResponseType,
  DeleteResponse,
  DeleteResponseType,
  VoidRequest,
  VoidRequestType,
} from '../types/common';
import baseApi, { HTTPMethod } from '../utils/baseApi';
import { createOptions } from '../utils/request';
import { createUpdateResponse } from '../utils/response';

// Schemas and Params
const SampleSchema = z.object({
  id: z.number().min(1),
  name: z.string().min(1),
  email: z.string().min(1),
  start: z.number().min(1),
  end: z.number().min(1),
});

// GET
const SearchSampleListFilter = z.object({ name: z.string().min(1) });
const SearchSampleListOption = createOptions(SearchSampleListFilter);

const GetSampleListResponse = z.array(SampleSchema);

export type SearchSampleListRequestType = z.infer<typeof SearchSampleListOption>;

export type SampleResponseType = z.infer<typeof SampleSchema>;
export type GetSampleListResponseType = z.infer<typeof GetSampleListResponse>;

// UPDATE
const UpdateSampleNameRequest = z.object({ name: z.string().min(1) });
const UpdateSampleListRequest = z.object({
  samples: z.array(SampleSchema),
});
const UpdateSampleResponse = createUpdateResponse(SampleSchema);

export type UpdateSampleRequestType = z.infer<typeof SampleSchema>;
export type UpdateSampleNameRequestType = z.infer<typeof UpdateSampleNameRequest>;
export type UpdateSampleListRequestType = z.infer<typeof UpdateSampleListRequest>;

export type UpdateSampleResponseType = z.infer<typeof UpdateSampleResponse>;
export type UpdateSampleListResponseType = z.infer<typeof UpdateSampleResponse>;

// GET
export const getSample = (id: number) =>
  baseApi<VoidRequestType, SampleResponseType>({
    method: HTTPMethod.GET,
    path: `/sample/${id}`,
    requestSchema: VoidRequest,
    responseSchema: SampleSchema,
  });

export const searchSampleList = baseApi<SearchSampleListRequestType, GetSampleListResponseType>({
  method: HTTPMethod.POST,
  path: '/samples/search',
  requestSchema: SearchSampleListOption,
  responseSchema: GetSampleListResponse,
});

// UPDATE
export const updateSampleColumn = (id: number) =>
  baseApi<UpdateSampleNameRequestType, UpdateSampleResponseType>({
    method: HTTPMethod.PUT,
    path: `/sample/${id}/name`,
    requestSchema: UpdateSampleNameRequest,
    responseSchema: UpdateSampleResponse,
  });

export const updateSample = (id: number) =>
  baseApi<UpdateSampleRequestType, UpdateSampleResponseType>({
    method: HTTPMethod.PUT,
    path: `/sample/${id}`,
    requestSchema: SampleSchema,
    responseSchema: UpdateSampleResponse,
  });

export const updateSampleList = baseApi<UpdateSampleListRequestType, UpdateSampleResponseType>({
  method: HTTPMethod.PUT,
  path: '/samples',
  requestSchema: UpdateSampleListRequest,
  responseSchema: UpdateSampleResponse,
});

// DELETE
export const deleteSample = (id: number) =>
  baseApi<VoidRequestType, DeleteResponseType>({
    method: HTTPMethod.DELETE,
    path: `/sample/${id}`,
    requestSchema: VoidRequest,
    responseSchema: DeleteResponse,
  });

export const deleteSampleList = baseApi<DeleteMultipleRequestType, DeleteMultipleResponseType>({
  method: HTTPMethod.PUT,
  path: '/samples/delete',
  requestSchema: DeleteMultipleObject,
  responseSchema: DeleteMultipleObject,
});
