import { AxiosError, AxiosRequestConfig } from 'axios';
import { SetterOrUpdater, Resetter } from 'recoil';

import { useRefreshAuthQuery } from './auth';
import { axiosClient } from './client';

import { AuthPostResponseType } from '@/api/auth';
import { ErrorMessageType, ErrorResponseType } from '@/shared/types/api/error';

export const axiosErrorHandle = (
  error: AxiosError<ErrorResponseType>,
): ErrorMessageType | undefined => {
  const statusCode = error.response?.status ?? 0;
  const errorDetail = error.response?.data;
  if (statusCode === 401) {
    return {
      type: 'authorizeRefresh',
    };
  }
  if (errorDetail && errorDetail?.errors) {
    const customErrorMessage = (error.config?.errorMessages ?? []).find(
      (e) => errorDetail?.errors.every(({ code }) => code === e.code),
    );
    if (customErrorMessage) return customErrorMessage;
    // const target = errorMessages.find(
    //   (e) => errorDetail?.errors.every(({ code }) => code === e.code),
    // );
    // if (target) return target;
  }

  switch (true) {
    case statusCode === 500:
      return { type: 'dialog' };
    case statusCode >= 400 && statusCode < 500:
    default:
      return {
        type: 'reject',
      };
  }
};

export const useErrorHandler = () => {
  const refreshAuthQuery = useRefreshAuthQuery();
  const setErrorHandler =
    ({
      refreshToken,
      setToken,
      resetToken,
      error,
    }: {
      refreshToken: string;
      setToken: SetterOrUpdater<AuthPostResponseType>;
      resetToken: Resetter;
      error: AxiosError<ErrorResponseType>;
    }) =>
    async () => {
      const errorMessage = axiosErrorHandle(error);
      switch (errorMessage?.type) {
        case 'errorBoundary':
          // バージョンアップでuseErrorHandler() が使えない（https://github.com/bvaughn/react-error-boundary/issues/132）
          throw error;
        case 'dialog':
          // errorMessage.messageDialog && setDialogState(errorMessage.messageDialog);
          break;
        case 'authorizeRefresh': {
          if (!refreshToken) return Promise.reject(error);
          const data = refreshAuthQuery.useRefresh({ refreshToken, setToken, resetToken });

          return axiosClient.request({
            ...error.config,
            headers: {
              ...error.config?.headers,
              Authorization: `Bearer ${data.access_token}`,
            },
          } as AxiosRequestConfig);
        }
        case 'reject':
        default:
          return Promise.reject(error);
      }
      return Promise.reject(error);
    };
  return { setErrorHandler };
};
