import React from 'react';

import { InternalAxiosRequestConfig, AxiosError } from 'axios';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';

import { useAuthInformation } from '@/api/utils/auth';
import { axiosClient } from '@/api/utils/client';
import { useErrorHandler } from '@/api/utils/error';
import { ErrorResponseType } from '@/shared/types/api/error';
import { tokenState } from '@/states/atoms/authInformationState';
import { tokenSelector } from '@/states/selectors/authInformationSelector';

type PropsType = {
  children: React.ReactElement;
};

export const AxiosClientProvider: React.FC<PropsType> = ({ children }) => {
  const tokenValue = useRecoilValue(tokenState);
  const setToken = useSetRecoilState(tokenSelector);
  const resetToken = useResetRecoilState(tokenSelector);
  // const setDialogState = useSetRecoilState(messageDialogSelector);
  const { setAuthInformation } = useAuthInformation();
  const { setErrorHandler } = useErrorHandler();

  React.useEffect(() => {
    const requestInterceptors = axiosClient.interceptors.request.use(
      // エラー解消のため、AxiosRequestConfig ⇒ InternalAxiosRequestConfigへ
      async (requestConfig: InternalAxiosRequestConfig) => {
        const accessToken = tokenValue?.access_token;
        const refreshToken = tokenValue?.refresh_token;
        return setAuthInformation({
          accessToken,
          refreshToken,
          setToken,
          resetToken,
          requestConfig,
        });
      },
    );

    const responseInterceptor = axiosClient.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<ErrorResponseType>) => {
        const refreshToken = tokenValue?.refresh_token;
        return setErrorHandler({
          refreshToken,
          setToken,
          resetToken,
          error,
        });
      },
    );

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptors);
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
  }, [
    resetToken,
    setToken,
    setAuthInformation,
    setErrorHandler,
    tokenValue?.access_token,
    tokenValue?.refresh_token,
  ]);

  return children;
};
