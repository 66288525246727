import React, { useEffect, useRef, useState } from 'react';

import { useAuthInformation } from '@/components/parts/AuthProvider/hooks';

type PropsType = {
  children: React.ReactElement;
};
export const AuthProvider: React.FC<PropsType> = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const initializedRef = useRef(false);
  const { initialize } = useAuthInformation();

  useEffect(() => {
    if (mounted || initializedRef.current) {
      return;
    }
    initializedRef.current = true;
    const runInitialize = async () => {
      await initialize();
      // 初期化済み
      setMounted(true);
    };
    runInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mounted ? children : null;
};
