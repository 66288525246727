import { rest } from 'msw';

import { AuthPostResponseType } from '@/api/auth';
import { config } from '@/api/config';

const authResponse = {
  access_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMzQ1Njc4OTAsInNlcnZpY2VUeXBlIjoxLCJhdXRob3JpdHkiOjEsImNvbnRyYWN0Ijp7ImF0dGFjaEZpbGVGb3JDb21tZW50Ijp0cnVlLCJjb250cmFjdFN0YXJ0RGF0ZSI6MTUxNjIzOTAyMiwicGF5bWVudFR5cGUiOjEsInR5cGUiOjEsInVuaXRMaW1pdCI6MTAwLCJ1bml0VHlwZSI6MX0sImlhdCI6MTUxNjIzOTAyMn0.xmoMSXJE-BrtosUkMFYX2WuUOC_zYIvhR542h6u1CtM',
  refresh_token: 'refresh_token',
  token_type: 'test',
  expires_in: new Date().valueOf(),
  scope: 'test',
};

export const auth = [
  rest.post<AuthPostResponseType>(`${config.baseURL}/authorize/code`, async (req, res, ctx) =>
    res(ctx.delay(5000), ctx.status(200), ctx.json(authResponse)),
  ),
];

export const refreshAuth = [
  rest.post<AuthPostResponseType>(`${config.baseURL}/authorize/refresh`, async (req, res, ctx) =>
    // 一旦上記と同様
    res(ctx.delay(1000), ctx.status(200), ctx.json(authResponse)),
  ),
];
