const baseUrl = import.meta.env.VITE_BASE_URL;
const baseHeaders = {
  'Content-Type': 'application/json;charset=utf-8',
  withCredentials: true,
};
const timeout = Number(import.meta.env.VITE_API_TIMEOUT);

export const config = {
  baseURL: baseUrl,
  headers: baseHeaders,
  timeout: Number.isNaN(timeout) ? 30000 : timeout,
};

// export const configWithoutAuth = {
//   baseURL: baseUrl,
//   headers: baseHeaders,
//   timeout: Number.isNaN(timeout) ? 30000 : timeout,
// };
