import { z } from 'zod';

import { AnyType } from '@/api/types/common';

const createSaveResponse = <T extends z.ZodType<AnyType>>(data: T) =>
  z.union([
    z.object({
      rows: z.array(data).nullish(),
    }),
    z.object({
      data,
    }),
  ]);

export const createRegisterResponse = <T extends z.ZodType<AnyType>>(data: T) =>
  createSaveResponse(data);

export const createUpdateResponse = <T extends z.ZodType<AnyType>>(data: T) =>
  createSaveResponse(data);
