export const parseQueryParams = (query: string): Record<string, any> => {
  const params = new URLSearchParams(query);
  const result: Record<string, any> = {};

  params.forEach((value, key) => {
    const layers = key.split(/\[|\]/).filter((k) => k);
    let currentLayer = result;
    layers.forEach((layer, index) => {
      if (index === layers.length - 1) {
        currentLayer[layer] = value;
      } else {
        // 存在しない場合は新しいオブジェクトまたは配列を作成
        if (!(layer in currentLayer)) {
          currentLayer[layer] = layers[index + 1].match(/^\d+$/) ? [] : {}; // 次のレイヤーが数値なら配列、それ以外ならオブジェクト
        }
        currentLayer = currentLayer[layer];
      }
    });
  });

  return result;
};
