import { useCallback } from 'react';

import jwtDecode from 'jwt-decode';
import { useSetRecoilState, useResetRecoilState } from 'recoil';

import api from '@/api';
import {
  AuthPostRequestType,
  AuthPostResponseType,
  RefreshAccessTokenRequestType,
} from '@/api/auth';
import { useOptimisticMutation } from '@/api/hooks/useMutationWrapper';
import { constants } from '@/shared/constants';
import { queryKeys } from '@/shared/constants/keys';
import { AuthType } from '@/shared/types/common/type';
import { tokenSelector } from '@/states/selectors/authInformationSelector';

export const getQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const stateParam = queryParams.get('state');
  const state = stateParam ? encodeURI(stateParam) : null;
  const localStorageRefreshToken = localStorage.getItem(
    constants.localstorage().authInformation.refreshToken,
  );

  if (!code && !localStorageRefreshToken) {
    console.log('code and refresh token not find ');
    // TODO 確認で一時的にエラーにする
    // throw Error('not found accessToken or local storage refresh token');
    // redirectLoginForm(true);
  }

  if (!state) localStorage.removeItem(constants.localstorage().authorizeState);
  return { code, state, localStorageRefreshToken };
};

export const useAuthQuery = ({
  code,
  state,
  localStorageRefreshToken,
}: {
  code: string | null;
  state: string | null;
  localStorageRefreshToken: string | null;
}) =>
  useOptimisticMutation(
    code
      ? {
          queryKey: [queryKeys.auth.authorize],
          fetcher: () =>
            api.auth.authorize({
              code,
              state,
              callbackUrl:
                import.meta.env.VITE_LOCAL_ENABLE === '1'
                  ? 'http://127.0.0.1:3000/'
                  : import.meta.env.VITE_CALLBACK_URL,
            }),
        }
      : {
          queryKey: [queryKeys.auth.refresh],
          fetcher: () => api.auth.refresh({ refreshToken: localStorageRefreshToken!! }),
        },
  );

export const removeItemsFromLocalstorage = () => {
  // localStorage.removeItem(constants.localstorage().userId);
  // localStorage.removeItem(constants.localstorage().authorizeState);
  localStorage.removeItem(constants.localstorage().authInformation.accessToken);
  localStorage.removeItem(constants.localstorage().authInformation.refreshToken);
  localStorage.removeItem(constants.localstorage().currentPath);
};

export const useAuthInformation = () => {
  const setToken = useSetRecoilState(tokenSelector);
  const resetToken = useResetRecoilState(tokenSelector);
  const { mutateAsync: mutateAsyncAuthorize } = useOptimisticMutation<
    AuthPostRequestType,
    AuthPostResponseType,
    any
  >({
    queryKey: [queryKeys.auth.authorize],
    fetcher: ({ code, state }) =>
      api.auth.authorize({
        code,
        state,
        callbackUrl:
          import.meta.env.VITE_LOCAL_ENABLE === '1'
            ? 'http://127.0.0.1:3000/'
            : import.meta.env.VITE_CALLBACK_URL,
      }),
  });
  const { mutateAsync: mutateAsyncRefresh } = useOptimisticMutation<
    RefreshAccessTokenRequestType,
    AuthPostResponseType,
    any
  >({
    queryKey: [queryKeys.auth.refresh],
    fetcher: ({ refreshToken }) => api.auth.refresh({ refreshToken }),
  });
  const initialize = useCallback(async () => {
    const { code, state, localStorageRefreshToken } = getQueryParams();
    if (!code && !localStorageRefreshToken) {
      // redirectLoginForm()
      return;
    }
    try {
      // TODO 両方ない時の考慮が必要
      const data = code
        ? await mutateAsyncAuthorize({ code, state })
        : await mutateAsyncRefresh({ refreshToken: localStorageRefreshToken! });
      console.log(`access_token: ${data?.access_token}`);
      const accessToken = data?.access_token;
      const refreshToken = data?.refresh_token;
      if (!accessToken || !refreshToken) {
        throw Error('not found accessToken');
      }
      const decodedAccessToken: AuthType = jwtDecode(accessToken);
      if (decodedAccessToken) {
        setToken(data);
      }
    } catch (error) {
      console.log('Error', error);
      resetToken();
      removeItemsFromLocalstorage();
      // redirectLoginForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialize };
};
