import { UseQueryResult } from '@tanstack/react-query';
import { InternalAxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { Resetter, SetterOrUpdater } from 'recoil';

import api from '..';
import { useQueryWrapper } from '../hooks/useQueryWrapper';

import { AuthPostResponseType } from '@/api/auth';
import { queryKeys } from '@/shared/constants/keys';
import { RefreshPostRequestType } from '@/shared/types/api/auth';
import { AuthType } from '@/shared/types/common/type';

export const useRefreshAuthQuery = () => {
  const useRefresh = ({ refreshToken, setToken, resetToken }: RefreshPostRequestType) => {
    const { data, isError } = useQueryWrapper({
      queryKey: [queryKeys.auth.refresh, refreshToken],
      queryFn: () => api.auth.refresh({ refreshToken }),
    }) as UseQueryResult<AuthPostResponseType>;

    if (isError) {
      resetToken();
      // redirectLoginForm();
      // TODO エラーハンドリング
      throw new Error(`Error Response: post refresh token`);
    } else {
      setToken(data!!);
    }
    return data as AuthPostResponseType;
  };
  return { useRefresh };
};

export const useAuthInformation = () => {
  const refreshAuthQuery = useRefreshAuthQuery();
  const setAuthInformation = ({
    accessToken,
    refreshToken,
    setToken,
    resetToken,
    requestConfig,
  }: {
    accessToken: string;
    refreshToken: string;
    setToken: SetterOrUpdater<AuthPostResponseType>;
    resetToken: Resetter;
    requestConfig: InternalAxiosRequestConfig;
  }) => {
    let token = accessToken;
    if (refreshToken && accessToken) {
      const decodedAccessToken: AuthType = jwtDecode(accessToken);
      const oneMinute = (new Date().getTime() + 60 * 1000) / 1000;
      const now = new Date().getTime() / 1000;

      if (decodedAccessToken.exp <= oneMinute || decodedAccessToken.exp <= now) {
        const data = refreshAuthQuery.useRefresh({ refreshToken, setToken, resetToken });
        // if (!decodedAccessToken.isValidRemoteIp) {
        //   window.location.href = import.meta.env.VITE_PORTAL_TOP_URL;
        // }
        token = data.access_token;
      }

      if (!requestConfig?.headers) {
        throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
      }

      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.Authorization = `Bearer ${token}`;
    } else {
      resetToken();
      console.log('access and refresh token not find ');
      // redirectLoginForm();
    }
    return requestConfig;
  };
  return { setAuthInformation };
};
