import { rest } from 'msw';

import {
  GetSampleListResponseType,
  SampleResponseType,
  UpdateSampleListResponseType,
  UpdateSampleResponseType,
} from '@/api/sample';
import { DeleteResponseType, DeleteMultipleResponseType } from '@/api/types/common';

const response = {
  id: 1,
  name: 'name',
  email: 'email',
  start: 1,
  end: 2,
};

const response2 = {
  id: 2,
  name: 'name2',
  email: 'email2',
  start: 10,
  end: 11,
};

const baseURL = `${import.meta.env.VITE_BASE_URL}`;

const mockGetSample = rest.get(`${baseURL}/sample/:id`, (req, res, ctx) => {
  const { id } = req.params;
  const result: SampleResponseType = {
    id: Number(id),
    name: 'name',
    email: 'email',
    start: 1,
    end: 2,
  };
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});

const mockSearchSampleList = rest.post(`${baseURL}/samples/search`, (req, res, ctx) => {
  const result: GetSampleListResponseType = [response, response2];
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});

const mockUpdateSample = rest.put(`${baseURL}/sample/:id`, async (req, res, ctx) => {
  const { id } = req.params;
  const result: UpdateSampleResponseType = {
    data: {
      id: Number(id),
      name: `name${id}`,
      email: `test${id}@test.com`,
      start: 1,
      end: 2,
    },
  };
  return res(
    ctx.delay(100),
    // ctx.delay(0),
    ctx.status(200),
    ctx.json(result),
  );
});
const mockUpdateSampleCulumn = rest.put(`${baseURL}/sample/:id/name`, async (req, res, ctx) => {
  const { name } = await req.json();
  const { id } = req.params;
  const result: UpdateSampleResponseType = {
    data: {
      id: Number(id),
      name,
      email: 'email',
      start: 1,
      end: 2,
    },
  };
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});
const mockUpdateSampleList = rest.put(`${baseURL}/samples`, async (req, res, ctx) => {
  const result: UpdateSampleListResponseType = {
    rows: [response, response2],
  };
  // const result: UpdateSampleListResponseType = {};
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});
const mockDeleteSample = rest.delete(`${baseURL}/sample/:id`, async (req, res, ctx) => {
  const { id } = req.params;
  const result: DeleteResponseType = {
    id: Number(id),
  };
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});
const mockDeleteSampleList = rest.put(`${baseURL}/samples/delete`, async (req, res, ctx) => {
  const { ids } = await req.json();
  const result: DeleteMultipleResponseType = {
    ids,
  };
  return res(ctx.delay(100), ctx.status(200), ctx.json(result));
});
export const mockSample = [
  mockGetSample,
  mockSearchSampleList,
  mockUpdateSample,
  mockUpdateSampleCulumn,
  mockUpdateSampleList,
  mockDeleteSampleList,
  mockDeleteSample,
];
