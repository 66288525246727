import React, { useMemo } from 'react';

import { GlobalStyles, ThemeProvider } from '@open-associates/roborobo-ui-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router';
import { RecoilRoot } from 'recoil';

import { AxiosClientProvider } from '@/api/axios';
import { AuthProvider } from '@/components/parts/AuthProvider';
import { ErrorFallback } from '@/components/parts/ErrorFallback';
import { router } from '@/components/parts/Routing';
import { DebugObserver } from '@/states/debugTool';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });
  const routes = useMemo(() => router(), []);
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        {/* NOTE: 開発用(Recoil Debug) */}
        {import.meta.env.VITE_DEBUG_ENABLE === '1' && <DebugObserver />}
        <ThemeProvider>
          <GlobalStyles />
          <ErrorBoundary fallback={<ErrorFallback />}>
            <AxiosClientProvider>
              <AuthProvider>
                <RouterProvider router={routes} />
              </AuthProvider>
            </AxiosClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
