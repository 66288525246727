import { useMemo, useState, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';

import { footers, routes, RoutesType } from '@/shared/routes';
import { PathUnionType } from '@/shared/routes/paths';
import { currentRouteState } from '@/states/atoms/currentRouteState';

export const useSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(true);

  const [currentRoute, setCurrentRouteState] = useRecoilState(currentRouteState);
  const menuRoutes = useMemo(() => routes.filter((route) => route.showMenu), []);

  const footerMenuRoutes = useMemo(() => footers.filter((route) => route.showMenu), []);
  const isActive = useCallback(
    (category: string, path?: PathUnionType) =>
      category === currentRoute.category && (path ? path === location.pathname : true),
    [currentRoute.category, location.pathname],
  );
  const handleChangeOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const clickMenu = useCallback(
    (selectedRoute: RoutesType) => {
      setCurrentRouteState(selectedRoute);
      navigate(selectedRoute.path);
    },
    [setCurrentRouteState, navigate],
  );
  const clickExternalLink = useCallback((url: string, isOpen?: boolean) => {
    if (isOpen) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }, []);

  return {
    open,
    menuRoutes,
    footerMenuRoutes,
    isActive,
    handleChangeOpen,
    clickMenu,
    clickExternalLink,
  };
};
