import { z } from 'zod';

import baseApi, { HTTPMethod } from '../utils/baseApi';
import { axiosClientWithoutAuth } from '../utils/client';

const AuthPostRequest = z.object({
  code: z.string().min(1),
  state: z.string().nullish(),
  callbackUrl: z.string().nullish(),
});

const RefreshAccessTokenRequest = z.object({
  refreshToken: z.string().min(1),
});

export type AuthPostRequestType = z.infer<typeof AuthPostRequest>;
export type RefreshAccessTokenRequestType = z.infer<typeof RefreshAccessTokenRequest>;

const AuthPostResponse = z.object({
  access_token: z.string().min(1),
  refresh_token: z.string().min(1),
  token_type: z.string().nullable(),
  expires_in: z.number().min(1),
  scope: z.string().nullable(),
});

export type AuthPostResponseType = z.infer<typeof AuthPostResponse>;

export const authorizeUser = baseApi<
  z.infer<typeof AuthPostRequest>,
  z.infer<typeof AuthPostResponse>
>({
  method: HTTPMethod.POST,
  path: '/authorize/code',
  requestSchema: AuthPostRequest,
  responseSchema: AuthPostResponse,
  client: axiosClientWithoutAuth,
});

export const refreshAccessToken = baseApi<
  z.infer<typeof RefreshAccessTokenRequest>,
  z.infer<typeof AuthPostResponse>
>({
  method: HTTPMethod.POST,
  path: '/authorize/refresh',
  requestSchema: RefreshAccessTokenRequest,
  responseSchema: AuthPostResponse,
  client: axiosClientWithoutAuth,
});
