import {
  IconDocumentNotes,
  IconHelp,
  IconKyuyokeisan,
  IconLogout,
  IconSetting,
  IconSoshiki,
  IconUsers,
} from '@open-associates/roborobo-ui-components';
import { RouteObject } from 'react-router';

import lazyImport from '@/components/parts/lazyImport';
import { PATHS, PathUnionType } from '@/shared//routes/paths';
import { loaderGetQueryString } from '@/shared/routes/loaderGetQueryString';

const Organizations = lazyImport(() => import('@/screens/Organizations'));

export type BreadcrumbType = {
  title: string;
  path?: PathUnionType;
};

export type RoutesType = RouteObject & {
  path: PathUnionType;
  breadcrumb?: BreadcrumbType[];
  title: string;
  menuTitle?: string;
  showMenu: boolean;
  Icon: React.ReactNode;
  category: string;
  subItemPaths?: PathUnionType[];
};

/** route */

export type ExternalLinkType = Omit<RoutesType, 'path' | 'element' | 'Component' | 'category'> & {
  path: string;
};

const commonRoute = {
  loader: loaderGetQueryString,
};

export const routes: RoutesType[] = [
  {
    ...commonRoute,
    title: '組織一覧',
    path: PATHS.ORGANIZATIONS,
    showMenu: true,
    menuTitle: '組織管理',
    Icon: <IconSoshiki />,
    element: <Organizations />,
    category: 'organizations',
    breadcrumb: [
      {
        title: '組織管理',
      },
      {
        title: '組織一覧',
        path: PATHS.ORGANIZATIONS,
      },
    ],
    subItemPaths: [PATHS.ORGANIZATIONS, PATHS.END_USERS],
  },
  {
    ...commonRoute,
    title: '契約一覧',
    path: PATHS.CONTRACTS,
    showMenu: true,
    menuTitle: '契約管理',
    Icon: <IconDocumentNotes />,
    element: <div>契約一覧</div>,
    category: 'contracts',
    breadcrumb: [
      {
        title: '契約管理',
      },
      {
        title: '契約一覧',
        path: PATHS.CONTRACTS,
      },
    ],
    subItemPaths: [PATHS.CONTRACTS, PATHS.INVOICES],
  },
  {
    ...commonRoute,
    title: '請求先一覧',
    path: PATHS.INVOICES,
    showMenu: false,
    Icon: <IconDocumentNotes />,
    element: <div>請求先一覧</div>,
    category: 'contracts',
    breadcrumb: [
      {
        title: '契約管理',
      },
      {
        title: '請求先一覧',
        path: PATHS.INVOICES,
      },
    ],
  },
  {
    ...commonRoute,
    title: '請求一覧',
    path: PATHS.BILLINGS,
    showMenu: true,
    menuTitle: '請求管理',
    Icon: <IconKyuyokeisan />,
    element: <div>請求一覧</div>,
    category: 'billings',
    breadcrumb: [
      {
        title: '請求管理',
      },
      {
        title: '請求一覧',
        path: PATHS.BILLINGS,
      },
    ],
  },
  {
    ...commonRoute,
    title: 'エンドユーザ一覧',
    path: PATHS.END_USERS,
    showMenu: false,
    menuTitle: 'エンドユーザ管理',
    Icon: <IconUsers />,
    element: <div>エンドユーザ一覧</div>,
    category: 'organizations',
    breadcrumb: [
      {
        title: 'エンドユーザ管理',
      },
      {
        title: 'エンドユーザ一覧',
        path: PATHS.END_USERS,
      },
    ],
  },
  {
    ...commonRoute,
    title: '商品一覧',
    path: PATHS.PRODUCTS,
    showMenu: true,
    menuTitle: '設定',
    Icon: <IconSetting />,
    element: <div>商品一覧</div>,
    category: 'setting',
    breadcrumb: [
      {
        title: '設定',
      },
      {
        title: '商品一覧',
        path: PATHS.PRODUCTS,
      },
    ],
    subItemPaths: [PATHS.PRODUCTS, PATHS.PLANS],
  },
  {
    ...commonRoute,
    title: 'プラン一覧',
    path: PATHS.PLANS,
    showMenu: false,
    Icon: <IconSetting />,
    element: <div>プラン一覧</div>,
    category: 'setting',
    breadcrumb: [
      {
        title: '設定',
      },
      {
        title: 'プラン一覧',
        path: PATHS.PLANS,
      },
    ],
  },
];

export const footers: ExternalLinkType[] = [
  {
    title: 'RoboRobo管理画面',
    path: `${import.meta.env.VITE_PORTAL_URL}/manage`,
    showMenu: true,
    menuTitle: 'RoboRobo管理画面',
    Icon: <IconHelp style={{ fontSize: '12px' }} />,
  },
  {
    title: 'ログアウト',
    // TODO ログアウトのURL設定すること
    path: `${import.meta.env.VITE_PORTAL_URL}`,
    showMenu: true,
    menuTitle: 'ログアウト',
    Icon: <IconLogout style={{ fontSize: '12px' }} />,
  },
];
