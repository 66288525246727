import { RoutesType } from '@/shared/routes';

export const findSubItemRoutes = (route: RoutesType, routes: RoutesType[]) => {
  if (!route.subItemPaths) {
    return undefined;
  }
  // subItemPathsの各パスに対応するルートを検索
  const subItemRoutes = route.subItemPaths
    .map((subItemPath) => routes.find((r) => r.path === subItemPath))
    .filter((subItemRoute): subItemRoute is RoutesType => !!subItemRoute); // TypeScriptの型ガードでnull/undefinedを除外

  return subItemRoutes;
};
