import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '@open-associates/roborobo-ui-components';

import { LayoutPcContents, MainContents, PageContents } from '@/components/parts/Layout/css';
import { useLayout } from '@/components/parts/Layout/hooks';
import { Loading } from '@/components/parts/Loading';
import { SideBar } from '@/components/parts/SideBar';

const Layout: React.FC = () => {
  const { currentRoute, breadcrumb } = useLayout();
  return (
    <LayoutPcContents>
      <SideBar />
      <MainContents>
        <Header
          Icon={currentRoute.Icon}
          breadcrumb={{
            items: breadcrumb,
          }}
          title={currentRoute.title}
        />
        <PageContents>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </PageContents>
      </MainContents>
    </LayoutPcContents>
  );
};

export default Layout;
