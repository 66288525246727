import { LoaderFunctionArgs } from 'react-router';

import { parseQueryParams } from './parseQueryParams';

/**
 * react routerのloader
 */
export const loaderGetQueryString = ({ request, params }: LoaderFunctionArgs) => {
  const searchQuery = new URL(request.url).searchParams;
  const data = parseQueryParams(searchQuery.toString());
  console.log('loaderGetQueryString', data);
  return [{ ...params, query: { ...data } }];
};
