import { z } from 'zod';

import { AnyType, SortOption } from '@/api/types/common';

export const createOptions = <T extends z.ZodType<AnyType>>(filter: T) =>
  z.object({
    sorts: z.array(SortOption).nullish(),
    filter,
    offset: z.number().nullish(),
    limit: z.number().nullish(),
  });
