import {
  deleteSample,
  deleteSampleList,
  getSample,
  searchSampleList,
  updateSample,
  updateSampleColumn,
  updateSampleList,
} from './sample';

import { authorizeUser, refreshAccessToken } from '@/api/auth';

export default {
  auth: {
    authorize: authorizeUser,
    refresh: refreshAccessToken,
  },
  sample: {
    get: getSample,
    search: searchSampleList,
    update: updateSample,
    updateCulumn: updateSampleColumn,
    bulkUpdate: updateSampleList,
    delete: deleteSample,
    bulkDelete: deleteSampleList,
  },
};
