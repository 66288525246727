import { ObjectToLiteralUnion } from '../utils/types';

export const PATHS = {
  ROOT: '/',
  ORGANIZATIONS: '/organizations',
  CONTRACTS: '/contracts',
  INVOICES: '/invoices',
  BILLINGS: '/billings',
  END_USERS: '/end_users',
  SETTINGS: '/settings',
  PRODUCTS: '/settings/products',
  PLANS: '/settings/plans',
} as const;

export type PathUnionType = ObjectToLiteralUnion<typeof PATHS>;
