import { useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';

import { useMatchedRoute } from '@/shared/hooks/useMatchedRoute';
import { currentRouteState } from '@/states/atoms/currentRouteState';

export const useLayout = () => {
  const navigate = useNavigate();

  const [currentRoute, setCurrentRouteState] = useRecoilState(currentRouteState);
  const matchedRoute = useMatchedRoute();
  const breadcrumb = useMemo(
    () =>
      currentRoute.breadcrumb?.map(({ title, path }) => ({
        label: title,
        to: path ? () => navigate(path) : () => {},
      })) ?? [],
    [currentRoute, navigate],
  );

  useEffect(() => {
    setCurrentRouteState(matchedRoute || currentRoute);
  }, [setCurrentRouteState, matchedRoute, currentRoute]);

  return {
    currentRoute,
    breadcrumb,
  };
};
