import React, { useEffect } from 'react';

const useLazyImportReload: React.FC = () => {
  useEffect(() => {
    window.location.reload();
  }, []);
  return <div />;
};

export default useLazyImportReload;
