import { type AxiosInstance } from 'axios';
import { type z } from 'zod';

import { axiosClient } from '@/api/utils/client';

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum HTTPStatusCode {
  OK = 200,
}

const baseApi =
  <Request, Response>({
    method,
    path,
    requestSchema,
    responseSchema,
    client,
  }: {
    method: HTTPMethod;
    path: string;
    requestSchema: z.ZodType<Request>;
    responseSchema: z.ZodType<Response>;
    client?: AxiosInstance;
  }): ((data: Request) => Promise<Response>) =>
  (requestData: Request) => {
    requestSchema.parse(requestData);

    async function apiCall() {
      const requestParams = {
        method,
        url: path,
        [method === HTTPMethod.GET ? 'params' : 'data']: requestData,
      };
      const { data } = client ? await client(requestParams) : await axiosClient(requestParams);

      if (import.meta.env.VITE_NODE_ENV !== 'develop') {
        responseSchema.safeParseAsync(data).then((result) => {
          if (!result.success) {
            // TODO: エラー表示
            console.log(`data: ${result.error}`);
          }
        });

        return data as Response;
      }

      return responseSchema.parse(data);
    }

    return apiCall();
  };

export default baseApi;
