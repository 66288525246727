import React from 'react';
import ReactDOM from 'react-dom/client';

import { worker } from './api/_mocks/browser';

import App from '@/screens/App';

const bootstrapApp = async () => {
  // Activate Mocking API
  if (import.meta.env.VITE_MOCK_ENABLE === '1') {
    await worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

bootstrapApp();
