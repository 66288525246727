import { RouteObject } from 'react-router';

import Layout from '@/components/parts/Layout';
import { PATHS } from '@/shared//routes/paths';

export const rootRoute: RouteObject = {
  id: 'root',
  path: PATHS.ROOT,
  element: <Layout />,
  loader: () => {
    // TODO 初回アクセス時にする処理があればここに入れる予定
    console.log('root: loader');
    return {};
  },
  children: [],
};
