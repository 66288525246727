export const queryKeys = {
  sample: {
    get: 'sample',
    list: 'sample-list',
    search: 'sample-search',
  },
  sample2: {
    search: 'sample2-search',
  },
  auth: {
    authorize: 'authorize',
    refresh: 'refresh',
  },
} as const;
